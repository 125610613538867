import React from 'react';
import PropTypes from 'prop-types';

const Button = ({variant = '', ...otherProps}) => (
  <button
    {...otherProps}
  />
);

Button.propTypes = {
  variant: PropTypes.string,
};

export default Button;
