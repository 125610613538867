import React from 'react';

class Alert extends React.Component {
  state = {
    isBoxVisible:true,
  };

  toggleBox = () => {
    this.setState(prevState => ({ isBoxVisible: !prevState.isBoxVisible }));
  };
  
  render() {
    const { isBoxVisible } = this.state;
  
    return (
      <div className={`box ${isBoxVisible ? '' : 'hidden'}`}>
        <p>Z uwagi na specyfikę aplikacji, sugerujemy korzystać z niej przy pomocy urządzeń z dużym ekranem. </p>
        <button onClick={this.toggleBox}>x</button>
      </div>
    );
  }
}

export default Alert;