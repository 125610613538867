import React from 'react';
import styles from './Form.module.scss';
import ReCAPTCHA from 'react-google-recaptcha';

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state ={ email: '', message: '', submitButtonEnabled: false };

    this.handleChange = this.handleChange.bind(this);
    this.recaptchaOnChange = this.recaptchaOnChange.bind(this);
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  recaptchaOnChange = function(value) {
    console.log('Recaptcha passed enabling button');
    this.setState({ submitButtonEnabled: true });
  };

  render() {
    const {email, message } = this.state;
    return (
      <div className={styles.formDiv}>
        <p>Skontaktuj się z nami</p>
        <form className={styles.form} method='post' data-netlify-recaptcha='true'>
          <input type='hidden' name='form-name' value='contact'/>
          <label>
            <p>Twoja wiadomość:</p>
            <textarea className= {styles.frame} rows= {4} name="message" value={message} onChange={this.handleChange} />
          </label>
          <label>
            <p>E-mail:</p>
            <input placeholder={'Jeśli oczekujesz odpowiedzi, podaj swój e-mail.'} className= {styles.frame} name ='email' value={email} onChange={this.handleChange} />
          </label>
          <ReCAPTCHA
            sitekey='6LfwBMwZAAAAAAJLpP9kI9k4bMQxGmQs0kF48tsX'
            onChange={this.recaptchaOnChange}
          />
          <button className={styles.submitButton} disabled={!this.state.submitButtonEnabled} type="submit">Wyślij</button>
        </form>
      </div>
    );
  }
}
export default Form;