import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { CookieBanner } from '@palmabit/react-cookie-law';


ReactDOM.render(
  <React.StrictMode>
    <App />
    <div>
      <CookieBanner
        message='Ta strona używa Google Analytics i ich ciasteczek do mierzenia aktywności na stronie. Jeżeli nie wyrażasz na to zgody, możesz zablokować tą funkcję przy pomocy wtyczki typu &quot;ad blocker&quot;.'
        acceptButtonText='OK'
        showPreferencesOption = {false}
        showMarketingOption = {false}
        showStatisticsOption = {false}
        privacyPolicyLinkText = ''
        styles={{
          dialog: { bottom: 0, position: 'fixed', width: '100%', background: '#e1e1e1', opacity: 0.7 },
          message: {fontFamily: 'Montserrat', paddingTop: 7, paddingBottom: 0, color: '#7c7c7c'},
          selectPane: {display: 'none'},
          button: {  float: 'right',
            background: 'white',
            border: '2px solid #008CBA',
            textAlign: 'center',
            lineHeight: 1.1,
            padding: '7px 15px',
            margin: '0 5px 10px',
            borderRadius: 5,
            fontWeight: 700, 
          },
        }}
      />
    </div>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
