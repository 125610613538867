import React from 'react';
import MainLayout from './components/layout/MainLayout/MainLayout';
import './styles/global.scss';



function App() {
  return (
    <div className="app">
      <MainLayout/>
    </div>
  );
}

export default App;
