
import React from 'react';
import Datasheet from 'react-datasheet';
import styles from './MainLayout.module.scss';
import Button from '../../common/Button/Button';
import moment from 'moment';
import currency from 'currency.js';
import ReactGA from 'react-ga';
import Alert from '../Alert/Alert';
import Form from '../Form/Form';
import Switch from 'react-switch';


ReactGA.initialize('UA-128903570-2', {
  debug: false,
  titleCase: false,
  testMode: false,
  gaOptions: {
    siteSpeedSampleRate: 100,
  },
});

const API_DATE_FORMAT = 'YYYY-MM-DD';
const NBP_URL = 'https://api.nbp.pl/api/exchangerates/rates/a';

class MainLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nbpData: [],
      precisionChecked: false,
      grid: [
        [
          { readOnly: true, value: '' },
          { value: 'Nazwa', readOnly: true },
          { value: 'Ilość', readOnly: true },
          { value: 'Waluta', readOnly: true },
          { value: 'Cena zakupu', readOnly: true },
          { value: 'Data zakupu', readOnly: true },
          { value: 'Cena sprzedaży', readOnly: true },
          { value: 'Data sprzedaży', readOnly: true },
          { value: 'Kurs PLN w dniu zakupu', readOnly: true },
          { value: 'Cena zakupu (PLN)', readOnly: true },
          { value: 'Kurs PLN w dniu sprzedaży', readOnly: true },
          { value: 'Cena sprzedaży PLN', readOnly: true },
          { value: 'Zysk (Strata)', readOnly: true },
        ],
        [{ readOnly: true, value: 1 }, { value: 'akcja' }, { value: '5' }, { value: 'EUR' }, { value: '200' }, { value: '2020-05-20' }, { value: '300' }, { value: '2020-06-10' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }],
        [{ readOnly: true, value: 2 }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }],
        [{ readOnly: true, value: 3 }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }],
        [{ readOnly: true, value: 4 }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }],
        [{ readOnly: true, value: 5 }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }],
        [{ readOnly: true, value: 6 }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }],
        [{ readOnly: true, value: 7 }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }],
        [{ readOnly: true, value: 8 }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }],
        [{ readOnly: true, value: 9 }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }],
        [{ readOnly: true, value: 10 }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }],
        [{ readOnly: true, value: 11 }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }],
        [{ readOnly: true, value: 12 }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }],
        [{ readOnly: true, value: 13 }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }],
        [{ readOnly: true, value: 14 }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }],
        [{ readOnly: true, value: 15 }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }],
        [{ readOnly: true, value: 16 }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }],
        [{ readOnly: true, value: 17 }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }],
        [{ readOnly: true, value: 18 }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }],
        [{ readOnly: true, value: 19 }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }],
        [{ readOnly: true, value: 20 }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }, { readOnly: true, value: '' }],

        [{ readOnly: true }, { readOnly: true, value: 'Suma', disableEvents: true }, { readOnly: true, value: '', disableEvents: true }, { readOnly: true, value: '' }, { readOnly: true, value: '', disableEvents: true }, { readOnly: true, value: '', disableEvents: true }, { readOnly: true, value: '', disableEvents: true }, { readOnly: true, value: '', disableEvents: true }, { readOnly: true, value: '', disableEvents: true }, { readOnly: true, value: '', disableEvents: true }, { readOnly: true, value: '', disableEvents: true }, { readOnly: true, value: '', disableEvents: true }, { readOnly: true, value: 0, className: 'total' }],

      ],
    };

    // TODO: do we really need this?
    this.handlePrecisionChange = this.handlePrecisionChange.bind(this);
  }

  curncy = function(input) {
    let curncySettings = {
      precision: 2,
      separator: '',
      decimal: ',',
    };

    if(this.state.precisionChecked) {
      curncySettings.precision = 4;
    }

    return currency(input, curncySettings);
  }

  componentDidMount() {
    ReactGA.pageview(window.location.href);
  }

  url = (inputDateStr, inputCurrencyStr) => {
    let endDate = moment(inputDateStr).subtract(1, 'days');
    let startDate = moment(inputDateStr).subtract(8, 'days');
    let startDateStr = startDate.format(API_DATE_FORMAT);
    let endDateStr = endDate.format(API_DATE_FORMAT);

    return `${NBP_URL}/${inputCurrencyStr}/${startDateStr}/${endDateStr}/`;
  }

  extractLastRate = (nbpResult) => {
    const rates = nbpResult.rates;
    const lastRateIdx = rates.length - 1;
    const lastRate = rates[lastRateIdx].mid;

    return lastRate;
  }
  //

  tryCalculateSum = (row) => {
    //console.log("row11", row[11].value);
    const purchasePrice = this.curncy(row[9].value);
    //console.log("row11 currency", currency(row[11].value, {precision: 2}));
    const sellPrice =  this.curncy(row[11].value);
    if (!Number.isNaN(purchasePrice) && !Number.isNaN(sellPrice)) {
      let rowtotal = sellPrice.subtract(purchasePrice);
      row[12].value = rowtotal.format();
    }
  }

  calculatePurchasePrice = () => {
    ReactGA.event({
      category: 'User',
      action: 'calculatePurchaseePrice',
    });

    let allPromise = [];

    for (let row of this.state.grid) {
      const rowIndex = row[0].value;

      if (rowIndex) {
        if (!this.areCellsValidated(row)) {
          continue;
        }
        const amount = row[2].value;
        const currencySymbol = row[3].value;
        const purchasePriceForeign = row[4].value;
        const purchaseDate = row[5].value;
        const sellPriceForeign = row[6].value;
        const sellDate = row[7].value;

        const purchaseDateUrl = this.url(purchaseDate, currencySymbol);
        let promise = fetch(purchaseDateUrl)
          .then(res => res.json())
          .then(
            (result) => {
              const exchangeRate = this.extractLastRate(result);
              row[8].value =  this.curncy(exchangeRate).format();
              const purchasePrice =  this.curncy(purchasePriceForeign).multiply(exchangeRate).multiply(amount);
              row[9].value = purchasePrice.format();
              // pkt 1 - wmyslic gdzie wpisac link do row[x]
              // ...
              // pkt 3 - napisac funkcje ktora obiekt result zamieni na brakujacy kawalek linku
              this.tryCalculateSum(row);
              this.setState(this.state);
            }
          );
        allPromise.push(promise);

        const sellDateUrl = this.url(sellDate, currencySymbol);
        let promise2 = fetch(sellDateUrl)
          .then(res => res.json())
          .then(
            (result) => {
              const exchangeRate = this.extractLastRate(result);
              row[10].value =  this.curncy(exchangeRate).format();
              const sellPrice =  this.curncy(sellPriceForeign).multiply(exchangeRate).multiply(amount);
              row[11].value = sellPrice.format();
              this.tryCalculateSum(row);
              this.setState(this.state);
            }
          );
        allPromise.push(promise2);
      }

    }
    let allPromises = Promise.all(allPromise);
    allPromises.then(() => {
      let totalCell = this.state.grid[this.state.grid.length - 1][12];
      let total = this.curncy(0);
      for (let row of this.state.grid) {
        const rowIndex = row[0].value;
        if (rowIndex) {
          total = total.add(row[12].value);
        }
      }
      totalCell.value = total.format();
      this.setState(this.state);

      ReactGA.event({
        category: 'User',
        action: 'calculatePurchaseePriceSuccess',
        value: allPromise.length / 2,
      });
    });

  }

  isCurrencyColumn = (j) => {
    return j === 3;
  }

  isDateColumn = (j) => {
    if (j === 5 || j === 7) {
      return true;
    }
  }

  isColumnWithDataToChange = (j) => {
    if (j === 1 || j === 2 || j === 3 || j === 4 || j === 5 || j === 6 || j === 7) {
      return true;
    }
  }

  validateCurrency = (cell) => {
    const AvailableCurrencies = ['EUR', 'USD', 'CHF','GBP','JPY','AUD','NZD','CAD','NOK','SEK'];
    const includes = AvailableCurrencies.includes(cell.value.toUpperCase());
    if (includes !== true && cell.value === '') {
      return true;
    } else if (includes !== true) {
      return false;
    } else {
      return true;
    }
  }

  validateDate = (cell) => {
    let data = cell.value;
    if (moment(data).isValid() === true || cell.value === '') {
      return true;
    } else {
      return false;
    }
  }

  isCellEmpty = (cell) => {
    return cell.value === '';
  }

  isCellValidated = (cell, j) => {
    if (this.isCurrencyColumn(j)) {
      return this.validateCurrency(cell);
    } else if (this.isDateColumn(j)) {
      return this.validateDate(cell);
    } else {
      return true;
    }
  }

  areCellsValidated = (row) => {
    let allCellsValidated = true;
    let fullRowEmpty = true;
    for (let j = 0; j < row.length; j++) {
      if (this.isColumnWithDataToChange(j)) {
        fullRowEmpty = fullRowEmpty && this.isCellEmpty(row[j]);
      }
    }

    if (fullRowEmpty) {
      return false;
    }

    for (let j = 0; j < row.length; j++) {
      let cell = row[j];
      let cellValid = true;

      if (this.isColumnWithDataToChange(j)) {
        if (this.isCellEmpty(cell)) {
          cell.className = 'unsupportedValue';
          cellValid = false;
        } else {
          if (this.isCellValidated(cell, j)) {
            cell.className = '';
          } else {
            cell.className = 'unsupportedValue';
            cellValid = false;
          }
        }
      }

      allCellsValidated = allCellsValidated && cellValid;
    }

    this.setState(this.state);
    return allCellsValidated;
  }

  handlePrecisionChange = function(checked) {
    this.setState({precisionChecked: checked});
  }

  render() {
    return (
      <div>
        <Alert/>
        <div className={styles.header}>
          <h1>Przelicz po kursie NBP &#40;beta&#41; </h1>
        </div>
        <Button className={styles.button} onClick={this.calculatePurchasePrice}>Przelicz</Button>

        <div>
          <p>Uzupełnij dane ręcznie, albo przekopiuj je z arkusza kalkulacyjnego.</p>
          <p>Więcej informacji znajdziesz poniżej arkusza <a href="#instruction">klik</a>.</p>
        </div>

        <div className ={styles.switchButton}>
          <p>Wyświetl kurs waluty z 4 miejscami po przecinku</p>
          <Switch onChange={this.handlePrecisionChange} checked={this.state.precisionChecked} />
        </div>

        <div className={styles.sheet}>
          <Datasheet
            data={this.state.grid}
            dataRenderer={(cell) => cell.value}
            valueRenderer={(cell) => cell.value}
            onContextMenu={(e, cell, i, j) => cell.readOnly ? e.preventDefault() : null}
            onCellsChanged={changes => {
              const grid = this.state.grid.map(row => [...row]);
              changes.forEach(({ cell, row, col, value }) => {
                grid[row][col] = { ...grid[row][col], value };
              });
              this.setState({ grid });
            }}
          />
        </div>
        <div id="instruction" className={styles.instruction}>
          <p>Kurs NBP = średni kurs NBP danej waluty obcej z ostatniego dnia roboczego poprzedzającego operację.</p>
          <p>Aplikacja obsługuje następujące waluty: EUR, USD, CHF, GBP, JPY, AUD, NZD, CAD, NOK, SEK.</p>
          <p>W polu &quot;Nazwa&quot; wspisz dowolne słowo, które pozwoli Ci na zidentyfikowanie dokonanej operacji. </p>
          <p>W kolumnie &quot;Ilość&quot; wpisz ilość zakupionych jednostek waloru.</p>
          <p>W kolumnie &quot;Waluta&quot; wpisz symbol waluty w której został dokonany zakup jednostek waloru.</p>
          <p>W polu &quot;Cena zakupu&quot; należy wpisać cenę po której dokonano zakupu danych jednostek.</p>
          <p>&quot;Data zakupu&quot; jest datą, w której dokonano zakupu jednostek waloru.</p>
          <p>W polu &quot;Cena sprzedaży&quot; należy wpisać cenę sprzedaży jednostek.</p>
          <p>&quot;Data sprzedaży&quot; jest datą, w której dokonano sprzedaży jednostek waloru.</p>
        </div>
        <Form/>
      </div>
    );
  }
}

export default MainLayout;
